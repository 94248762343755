const name = 'snackbar'

//initialState
const initialState = {
  status: 'initial',
  data: {}
}

// Actions
const types = {
  ACTION_SET: `store/${name}/SET`,
  ACTION_REMOVE: `store/${name}/REMOVE`,
  ACTION_RESET: `store/${name}/RESET`
}

// Reducer
const reducers = {}
reducers[name] = function (state = initialState, action) {
  switch (action.type) {
    case types.ACTION_SET:
      return { ...state, data: { ...state.data, ...action.payload } }
    case types.ACTION_RESET:
      return { ...state, ...initialState }
    default:
      return state
  }
}

//Action creators
const actions = {}
actions[name] = (payload) => ({ type: types.ACTION_SET, payload, actionId: payload?.actionId ?? Date.now() })
actions[`${name}Remove`] = (payload) => ({ type: types.ACTION_REMOVE, payload, actionId: payload?.actionId ?? Date.now() })
actions[`${name}Reset`] = (payload) => ({ type: types.ACTION_RESET, payload, actionId: payload?.actionId ?? Date.now() })

export const slice = {
  types,
  actions,
  sagas: [],
  selectors: {}
}
export default reducers
