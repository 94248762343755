// project import
import getColors from 'helpers/getColors'

// ==============================|| CHIP - COLORS ||============================== //

function getColor({ color, theme }) {
  const colors = getColors(theme, color)
  const { dark } = colors

  return {
    '&.Mui-focusVisible': {
      outline: `2px solid ${dark}`,
      outlineOffset: 2
    }
  }
}

function getLightColorStyle({ color, theme }) {
  const colors = getColors(theme, color)
  const { lighter, main, dark, contrastText } = colors

  return {
    color: contrastText,
    backgroundColor: lighter,
    borderColor: main,
    '&>.MuiChip-avatar': {
      backgroundColor: main,
      color: dark
    }
  }
}

function getColorStyle({ color, theme }) {
  const colors = getColors(theme, color)
  const { light, main, darker, contrastText } = colors

  return {
    color: contrastText,
    backgroundColor: main,
    borderColor: '#fff', // light
    '& .MuiChip-deleteIcon': {
      color: main,
      '&:hover': {
        color: light
      }
    },
    '&>.MuiChip-icon': {
      color: theme.palette.contrast.main,
      backgroundColor: darker,
      fontSize: theme.typography.h3.fontSize,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.25)
    }
  }
}

// ==============================|| OVERRIDES - CHIP ||============================== //

export default function Chip(theme) {
  const defaultLightChip = getColorStyle({ color: 'primary', theme })
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          fontSize: '0.75rem',
          '&:active': {
            boxShadow: 'none'
          },
          '&.MuiChip-colorPrimary': getColor({ color: 'primary', theme }),
          '&.MuiChip-colorSecondary': getColor({ color: 'secondary', theme }),
          '&.MuiChip-colorError': getColor({ color: 'error', theme }),
          '&.MuiChip-colorInfo': getColor({ color: 'info', theme }),
          '&.MuiChip-colorSuccess': getColor({ color: 'success', theme }),
          '&.MuiChip-colorWarning': getColor({ color: 'warning', theme })
        },
        sizeLarge: {
          fontSize: '1rem',
          height: 40
        },
        light: {
          border: '1px solid',
          ...defaultLightChip,
          '&.MuiChip-lightPrimary': getLightColorStyle({ color: 'primary', theme }),
          '&.MuiChip-lightSecondary': getLightColorStyle({ color: 'secondary', theme }),
          '&.MuiChip-lightError': getLightColorStyle({ color: 'error', theme }),
          '&.MuiChip-lightInfo': getLightColorStyle({ color: 'info', theme }),
          '&.MuiChip-lightSuccess': getLightColorStyle({ color: 'success', theme }),
          '&.MuiChip-lightWarning': getLightColorStyle({ color: 'warning', theme })
        },
        combined: {
          border: '1px solid',
          ...defaultLightChip,
          '&.MuiChip-combinedPrimary': getColorStyle({ color: 'primary', theme }),
          '&.MuiChip-combinedSecondary': getColorStyle({ color: 'secondary', theme }),
          '&.MuiChip-combinedError': getColorStyle({ color: 'error', theme }),
          '&.MuiChip-combinedInfo': getColorStyle({ color: 'info', theme }),
          '&.MuiChip-combinedSuccess': getColorStyle({ color: 'success', theme }),
          '&.MuiChip-combinedWarning': getColorStyle({ color: 'warning', theme })
        }
      }
    }
  }
}
