// ==============================|| OVERRIDES - DATA GRID ||============================== //

export default function Dialog(theme) {
  return {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: 8,
            borderRadius: 8,
            backgroundColor: theme.palette.secondary.light
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            // backgroundColor: theme.palette.text.disabled
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: 8,
            backgroundColor: theme.palette.secondary.light
          }
        }
      }
    }
  }
}
