import { combineReducers } from 'redux'

import usersRootReducer from './users'
import rolesRootReducer from './roles'
import facilitiesRootReducer from './facilities'
import suitsRootReducer from './suites'
import residentsRootReducer from './residents'
import partnersRootReducer from './partners'
import settingsRootReducer from './settings'
import apiKeysRootReducer from './apiKeys'
import appRootReducer from './app'
import censusesRootReducer from './censuses'
import ratesRootReducer from './rates'
import billingsRootReducer from './billings'
import billsRootReducer from './bills'
import billItemsRootReducer from './billItems'
import rentRollsRootReducer from './rentRolls'
import trustTransactionsRootReducer from './trustTransactions'
import healthReviewsRootReducer from './healthReviews'
import trustFacilityBalancesRootReducer from './trustFacilityBalances'
import trustBalancesRootReducer from './trustBalances'
import paymentsRootReducer from './payments'
import paymentsBatchRootReducer from './paymentsBatch'
import paymentsBatchItemsRootReducer from './paymentsBatchItems'

const rootReducer = combineReducers({
  users_data: usersRootReducer,
  roles_data: rolesRootReducer,
  facilities_data: facilitiesRootReducer,
  suites_data: suitsRootReducer,
  residents_data: residentsRootReducer,
  partners_data: partnersRootReducer,
  settings_data: settingsRootReducer,
  api_keys_data: apiKeysRootReducer,
  app_data: appRootReducer,
  censuses_data: censusesRootReducer,
  rates_data: ratesRootReducer,
  billings_data: billingsRootReducer,
  bills_data: billsRootReducer,
  billItems_data: billItemsRootReducer,
  rentRolls_data: rentRollsRootReducer,
  trusts_data: trustTransactionsRootReducer,
  healthReviews_data: healthReviewsRootReducer,
  trustFacilityBalances_data: trustFacilityBalancesRootReducer,
  trustBalances_data: trustBalancesRootReducer,
  payments_data: paymentsRootReducer,
  paymentsBatch_data: paymentsBatchRootReducer,
  paymentsBatchItems_data: paymentsBatchItemsRootReducer
})

export default rootReducer
