import { combineReducers } from 'redux'
import _ from 'lodash'

import * as paymentsList from './list'
import * as getPayment from './get'
import * as createPayment from './create'
import * as editPayment from './edit'
import * as deletePayment from './delete'

const slices = {
  paymentsList,
  getPayment,
  createPayment,
  editPayment,
  deletePayment
}

const paymentTypes = _.mapValues(slices, (s) => s.slice.types)
const paymentActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const paymentSagas = _(slices).values().map('slice.sagas').flatten().value()
const paymentSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { paymentTypes, paymentActions, paymentSagas, paymentSelectors }

export default combineReducers(reducers)
