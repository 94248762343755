import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCookie } from 'helpers/cookies'
import { userActions } from 'store/users'
import { Navigate } from 'react-router-dom'

import Preloader from 'components/Loader'
import Alerts from 'components/notifications/Alerts'

const envName = process.env.NODE_ENV

const Component = (props) => {
  const { requirements, children, childrenIfLoading, childrenIfNoAuth, childrenIfNoPermission } = props

  const dispatch = useDispatch()
  const checkPermissionsCollection = (payload) => dispatch(userActions.checkPermissionsCollection(payload))
  const checkPermissionsCollectionRes = useSelector((state) => state.users_data.checkPermissionsCollection.data[requirements.componentId])
  useEffect(() => {
    const accessToken = getCookie(`${process.env.REACT_APP_WEBSITE_NAME}accessToken`)
    if (accessToken) {
      const payload = {
        data: { endpoints: requirements.endpoints },
        token: accessToken,
        actionId: requirements.componentId
      }
      checkPermissionsCollection(payload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const status = checkPermissionsCollectionRes?.status ?? ''
  const hasRequestedPermissions = checkPermissionsCollectionRes?.data?.hasRequestedPermissions
  const error = checkPermissionsCollectionRes?.data?.error ?? 'Failed to load permissions.'

  // check if auth required
  if (requirements.auth && !getCookie(`${process.env.REACT_APP_WEBSITE_NAME}accessToken`)) {
    if (childrenIfNoAuth) {
      return childrenIfNoAuth
    }

    const url = new URL(window.location.href)
    const returnUrl = window.btoa(url.pathname + url.search + url.hash)
    // const returnUrl = window.btoa(window.location.href)

    return <Navigate to={`/login?redirectTo=${returnUrl}`} />
  }

  // check  if has enough permissions
  if (['', 'initial', 'loading'].includes(status)) {
    if (childrenIfLoading) {
      return childrenIfLoading
    }

    if (childrenIfLoading === null) {
      return null
    }

    return <Preloader show={true} />
  }

  if (status === 'success' && !hasRequestedPermissions) {
    if (childrenIfNoPermission) {
      return childrenIfNoPermission
    }

    if (childrenIfNoPermission === null) {
      return null
    }

    return <Navigate to="/404" />
  }

  if (['fail', 'error'].includes(status)) {
    //the case when api requires user to be authorized
    if (error === 'You must be authorized') {
      if (childrenIfNoAuth) {
        return childrenIfNoAuth
      }
      const url = new URL(window.location.href)
      const returnUrl = window.btoa(url.pathname + url.search + url.hash)
      // const returnUrl = window.btoa(window.location.href)

      return <Navigate to={`/login?redirectTo=${returnUrl}`} />
    }

    //the case when user has no permissions
    if (error === 'No permissions') {
      if (childrenIfNoPermission) {
        return childrenIfNoPermission
      }

      if (childrenIfNoPermission === null) {
        return null
      }

      return <Navigate to="/404" />
    }

    if (envName === 'development') {
      return (
        <div>
          <Alerts alerts={error} />
        </div>
      )
    }

    return <Navigate to="/500" />
  }

  return children
}

export default Component
