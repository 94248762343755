import { useRef, useState } from 'react'
import { useNavigateCustom } from 'helpers/useNavigateCustom'
import { useTheme } from '@mui/material/styles'
import { Avatar, Box, ButtonBase, CardContent, ClickAwayListener, Grid, IconButton, Paper, Popper, Stack, Typography } from '@mui/material'
import { eraseCookie } from 'helpers/cookies'
import MainCard from 'components/MainCard'
import Transitions from 'components/@extended/Transitions'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import { useSelector } from 'react-redux'

const Profile = () => {
  const theme = useTheme()
  const navigateCustom = useNavigateCustom()

  const name = 'getMyProfile'
  const stateName = 'users_data'
  const itemRes = useSelector((state) => state[stateName][name])
  // const status = itemRes?.status ?? ''
  // const error = itemRes?.data?.error ?? 'Error'
  const item = itemRes?.data?.item ?? {}
  const userName = `${item?.firstName ?? ''} ${item?.lastName ?? ''}`.trim()
  const profileImgUrl = `${process.env.REACT_APP_API_URL}/uploads/${item?.profileImage}`
  let initials = `${item?.firstName?.charAt(0)?.toUpperCase() ?? ''}${item?.lastName?.charAt(0)?.toUpperCase() ?? ''}`.trim()
  if (!initials) {
    initials = `${item?.email?.charAt(0)?.toUpperCase() ?? ''}${item?.email?.charAt(1)?.toUpperCase() ?? ''}`.trim()
  }
  const position = item?.position || ''

  const handleLogout = async (e) => {
    e.preventDefault()
    eraseCookie(`${process.env.REACT_APP_WEBSITE_NAME}accessToken`)
    document.location.reload()
  }

  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const iconBackColorOpen = 'grey.300'

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: 'secondary.lighter' }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          {item?.profileImage ? (
            <Avatar alt={initials} src={profileImgUrl} sx={{ width: 32, height: 32, borderRadius: '4px' }} variant="square" />
          ) : (
            <Avatar
              alt="User Profile"
              variant="square"
              sx={{
                width: 32,
                height: 32,
                color: 'contrast.main',
                bgcolor: 'secondary.dark',
                fontSize: '1.2em',
                borderRadius: '4px'
              }}
            >
              {initials}
            </Avatar>
          )}
        </Stack>
      </ButtonBase>
      <Popper
        sx={{ zIndex: 100 }}
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  // boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5 }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item style={{ cursor: 'pointer' }} onClick={() => navigateCustom('/profile')}>
                          <Stack direction="row" spacing={1.25} alignItems="center">
                            {item?.profileImage ? (
                              <Avatar
                                alt={initials}
                                src={profileImgUrl}
                                sx={{ width: 32, height: 32, borderRadius: '4px' }}
                                variant="square"
                              />
                            ) : (
                              <Avatar
                                alt="User Profile"
                                variant="square"
                                sx={{
                                  width: 32,
                                  height: 32,
                                  color: 'contrast.main',
                                  bgcolor: 'secondary.dark',
                                  fontSize: '1.2em',
                                  borderRadius: '4px'
                                }}
                              >
                                {initials}
                              </Avatar>
                            )}
                            <Stack>
                              <Typography variant="h6">{userName}</Typography>
                              <Typography variant="body2" color="textSecondary">
                                {position}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item>
                          <IconButton size="large" color="secondary" onClick={handleLogout}>
                            <LogoutRoundedIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  )
}

export default Profile
