import ThemeCustomization from 'theme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import ScrollTop from 'components/ScrollTop'
import Routes from 'routes'
import Snackbar from 'components/@extended/Snackbar'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const App = () => (
  <ThemeCustomization>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ScrollTop>
        <Routes />
        <Snackbar />
      </ScrollTop>
    </LocalizationProvider>
  </ThemeCustomization>
)

export default App
