// ==============================|| OVERRIDES - ICON BUTTON ||============================== //

export default function Button() {
  return {
    MuiButton: {
      styleOverrides: {
        root: {},
        sizeMedium: {
          lineHeight: 1.5,
          padding: '0.6429em 1.1429em'
        },
        startIcon: {
          '>*:nth-of-type(1)': {
            fontSize: '1em',
            marginLeft: 0
          }
        },
        containedError: {
          color: '#fff'
        }
      }
    }
  }
}
