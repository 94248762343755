import { has } from 'lodash'

// ==============================|| CUSTOM FUNCTION - COLORS ||============================== //

const getColors = (theme, color) => {
  if (has(theme.palette, color)) {
    return theme.palette[color]
  }

  console.error(`${color} not found in theme palette`)
  return theme.palette.primary
}

export default getColors
