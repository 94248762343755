import { lazy } from 'react'
import Loadable from 'components/Loadable'
import MinimalLayout from 'layout/MinimalLayout'

const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')))
const ForgotPassword = Loadable(lazy(() => import('pages/authentication/ForgotPassword')))
const ResetPassword = Loadable(lazy(() => import('pages/authentication/ResetPassword')))

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />
    },
    {
      path: 'reset-password/:_token',
      element: <ResetPassword />
    }
  ]
}

export default LoginRoutes
