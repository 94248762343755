// assets
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'At a Glance',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/',
      icon: DashboardRoundedIcon,
      breadcrumbs: false,
      requirements: {
        componentId: 'NavItem__Dashboard',
        auth: true,
        endpoints: [{ method: 'GET', path: '/user' }]
      }
      /*
      chip: {
        label: 'new',
        color: 'secondary',
        size: 'small',
        variant: 'combined'
      }
      */
    }
  ]
}

export default dashboard
