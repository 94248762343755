import PropTypes from 'prop-types'
import { forwardRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { appActions as Actions } from '../../../store/app'
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'

const NavItem = ({ item, level, style }) => {
  const actionName = 'menu'
  const dispatch = useDispatch()
  const doAction = (payload) => dispatch(Actions[actionName](payload))

  let itemTarget = '_self'
  if (item.target) {
    itemTarget = '_blank'
  }

  // eslint-disable-next-line react/display-name
  let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) }
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget }
  }

  const itemHandler = (id) => {
    const payload = { openItem: [id] }
    doAction(payload)
  }

  const Icon = item.icon
  const itemIcon = item.icon ? <Icon style={{ fontSize: '1.25rem' }} /> : false

  const isSelected = false // = openItem.findIndex((id) => id === item.id) > -1

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id)
    if (currentIndex > -1) {
      const payload = { openItem: [item.id] }
      doAction(payload)
    }
    // eslint-disable-next-line
  }, [])

  const textColor = level !== 1 ? 'text.primary' : 'text.accent'

  return (
    <ListItemButton sx={style} {...listItemProps} disabled={item.disabled} onClick={() => itemHandler(item.id)} selected={isSelected}>
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: textColor
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          <Typography variant="h6" sx={{ color: textColor }}>
            {item.title}
          </Typography>
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          sx={{ marginLeft: '5px' }}
        />
      )}
    </ListItemButton>
  )
}

NavItem.propTypes = {
  item: PropTypes.object,
  style: PropTypes.object,
  level: PropTypes.number
}

export default NavItem
