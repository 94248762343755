// import { red } from '@mui/material/colors'

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = () => ({
  mode: 'light',
  primary: {
    main: '#009688',
    light: '#1AA391',
    lighter: '#5BBDA9',
    dark: '#007069',
    darker: '#004A47',
    contrastText: '#fff'
  },
  secondary: {
    main: '#D9D9D9',
    light: '#F5F5F5',
    lighter: '#FFFFFF',
    dark: '#8C8C8C',
    darker: '#434343',
    contrastText: '#262626'
  },
  success: {
    main: '#00A854',
    light: '#26B56E',
    lighter: '#E0F5EA',
    dark: '#00A04D',
    darker: '#008D3A',
    // contrastText: '#004322'
    contrastText: '#fff'
  },
  contrast: {
    main: '#FFFFFF'
  },
  error: {
    main: '#F04134',
    light: '#F25E52',
    lighter: '#FDE8E7',
    dark: '#EE3B2F',
    darker: '#E92A21',
    // contrastText: '#601A15'
    contrastText: '#fff'
  },
  warning: {
    main: '#FFBF00',
    light: '#FFC926',
    lighter: '#FFF7E0',
    dark: '#FFB900',
    darker: '#FFA900',
    // contrastText: '#664C00'
    contrastText: '#fff'
  },
  info: {
    main: '#00A2AE',
    light: '#26B0BA',
    lighter: '#E0F4F5',
    dark: '#009AA7',
    darker: '#008694',
    // contrastText: '#004146'
    contrastText: '#fff'
  },
  background: {
    default: '#FAFAFA'
  },
  grey1: '#FAFAFA',
  text: {
    primary: '#262626',
    secondary: '#8C8C8C',
    // secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    accent: '#FFFFFF',
    white: '#FFFFFF',
    warning: '#FFA900'
  }
})

export default Palette
