import { combineReducers } from 'redux'
import _ from 'lodash'

import * as trustBalancesList from './list'
import * as getTrustBalance from './get'
import * as createTrustBalance from './create'
import * as editTrustBalance from './edit'
import * as deleteTrustBalance from './delete'
import * as trustBalancesListLookup from './listLookup'

const slices = {
  trustBalancesList,
  getTrustBalance,
  createTrustBalance,
  editTrustBalance,
  deleteTrustBalance,
  trustBalancesListLookup
}

const trustBalanceTypes = _.mapValues(slices, (s) => s.slice.types)
const trustBalanceActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const trustBalanceSagas = _(slices).values().map('slice.sagas').flatten().value()
const trustBalanceSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { trustBalanceTypes, trustBalanceActions, trustBalanceSagas, trustBalanceSelectors }

export default combineReducers(reducers)
