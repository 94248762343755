import { combineReducers } from 'redux'
import _ from 'lodash'

import * as paymentsBatchList from './list'
import * as getPaymentsBatch from './get'
import * as createPaymentsBatch from './create'
import * as editPaymentsBatch from './edit'
import * as deletePaymentsBatch from './delete'
import * as processPaymentBatch from './processPaymentstBatch'

const slices = {
  paymentsBatchList,
  getPaymentsBatch,
  createPaymentsBatch,
  editPaymentsBatch,
  deletePaymentsBatch,
  processPaymentBatch
}

const paymentBatchTypes = _.mapValues(slices, (s) => s.slice.types)
const paymentBatchActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const paymentBatchSagas = _(slices).values().map('slice.sagas').flatten().value()
const paymentBatchSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { paymentBatchTypes, paymentBatchActions, paymentBatchSagas, paymentBatchSelectors }

export default combineReducers(reducers)
