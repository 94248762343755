import { combineReducers } from 'redux'
import _ from 'lodash'

import * as rentRollsList from './rentRollsList'
import * as getRentRoll from './getRentRoll'
import * as rentRollsListTypeahead from './rentRollsListTypeahead'

const slices = {
  rentRollsList,
  getRentRoll,
  rentRollsListTypeahead
}

const rentRollTypes = _.mapValues(slices, (s) => s.slice.types)
const rentRollActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const rentRollSagas = _(slices).values().map('slice.sagas').flatten().value()
const rentRollSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { rentRollTypes, rentRollActions, rentRollSagas, rentRollSelectors }

export default combineReducers(reducers)
