import _ from 'lodash'
import { Alert, Stack } from '@mui/material'

const Component = (props) => {
  const { alerts } = props

  let alertsArray = []

  if (!alerts) {
    return null
  }

  if (_.isArray(alerts)) {
    alertsArray = alerts
  }

  if (_.isString(alerts)) {
    alertsArray.push(alerts)
  }

  if (_.isEmpty(alertsArray)) {
    return null
  }

  const alertsStack = alertsArray.map((message, idx) => {
    let msg = 'Error happened'

    if (_.isString(message)) {
      msg = message
    }

    if (_.isString(message?.msg)) {
      msg = message?.msg
    }

    if (_.isArray(message?.msg)) {
      return (
        <Stack key={idx} sx={{ width: '100%' }} spacing={2}>
          {message.msg.map((subMsg, subIdx) => (
            <Alert key={`${idx}-${subIdx}`} severity="error">
              {subMsg}
            </Alert>
          ))}
        </Stack>
      )
    }

    return (
      <Alert key={idx} severity="error">
        {msg}
      </Alert>
    )
  })

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      {alertsStack}
    </Stack>
  )
}

export default Component
