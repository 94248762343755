import { createRoot } from 'react-dom/client'
import App from './App'
import '@fontsource/inter'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'

const store = configureStore()
// const envName = process.env.NODE_ENV
// if (envName === 'development' || envName === 'staging') {
//   store.subscribe(() => console.log('storeLog: ', store.getState()))
// }

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)
