import { combineReducers } from 'redux'
import _ from 'lodash'

import * as suitesList from './suitesList'
import * as getSuite from './getSuite'
import * as createSuite from './createSuite'
import * as editSuite from './editSuite'
import * as deleteSuite from './deleteSuite'
import * as suitesListTypeahead from './suitesListTypeahead'

const slices = {
  suitesList,
  getSuite,
  createSuite,
  editSuite,
  deleteSuite,
  suitesListTypeahead
}

const suiteTypes = _.mapValues(slices, (s) => s.slice.types)
const suiteActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const suiteSagas = _(slices).values().map('slice.sagas').flatten().value()
const suiteSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { suiteTypes, suiteActions, suiteSagas, suiteSelectors }

export default combineReducers(reducers)
