import PropTypes from 'prop-types'

// material-ui
import { Typography } from '@mui/material'

// project import
import NavItem from './NavItem'
import NavCollapse from './NavCollapse'
import CheckPermissions from 'components/permissions/CheckPermissions'

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroupCheckPermissions = (props) => {
  const { children, requirements } = props

  if (!requirements) {
    return children
  }

  return (
    <CheckPermissions requirements={requirements} childrenIfNoPermission={null}>
      {children}
    </CheckPermissions>
  )
}

const NavGroup = ({ item }) => {
  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case 'collapse':
        return (
          <NavGroupCheckPermissions key={menuItem.id} requirements={menuItem.requirements}>
            <NavCollapse menu={menuItem} level={1} />
          </NavGroupCheckPermissions>
        )
      case 'item':
        return (
          <NavGroupCheckPermissions key={menuItem.id} requirements={menuItem.requirements}>
            <NavItem item={menuItem} level={1} style={{ flexGrow: 0 }} />
          </NavGroupCheckPermissions>
        )
      default:
        return (
          <Typography key={menuItem.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        )
    }
  })

  return navCollapse
}

NavGroup.propTypes = {
  item: PropTypes.object
}

export default NavGroup
