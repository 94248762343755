import { combineReducers } from 'redux'
import _ from 'lodash'

import * as trustFacilityBalancesList from './list'
import * as getTrustFacilityBalance from './get'
import * as createTrustFacilityBalance from './create'
import * as editTrustFacilityBalance from './edit'
import * as deleteTrustFacilityBalance from './delete'
import * as trustFacilityBalancesListLookup from './listLookup'

const slices = {
  trustFacilityBalancesList,
  getTrustFacilityBalance,
  createTrustFacilityBalance,
  editTrustFacilityBalance,
  deleteTrustFacilityBalance,
  trustFacilityBalancesListLookup
}

const trustFacilityBalanceTypes = _.mapValues(slices, (s) => s.slice.types)
const trustFacilityBalanceActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const trustFacilityBalanceSagas = _(slices).values().map('slice.sagas').flatten().value()
const trustFacilityBalanceSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { trustFacilityBalanceTypes, trustFacilityBalanceActions, trustFacilityBalanceSagas, trustFacilityBalanceSelectors }

export default combineReducers(reducers)
