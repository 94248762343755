import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCookie } from 'helpers/cookies'
import { userActions as Actions } from 'store/users'

import Alerts from 'components/notifications/Alerts'
import Preloader from 'components/Loader'

import Form from './Form'

const Component = () => {
  const name = 'getMyProfile'
  const stateName = 'users_data'

  const dispatch = useDispatch()
  const itemQuery = (payload) => dispatch(Actions[name](payload))
  const itemRes = useSelector((state) => state[stateName][name])
  const status = itemRes?.status ?? ''
  const error = itemRes?.data?.error ?? 'Error'
  //const item = itemRes?.data?.item ?? {}
  //const items = itemRes?.data?.items ?? []

  useEffect(() => {
    const accessToken = getCookie(`${process.env.REACT_APP_WEBSITE_NAME}accessToken`)
    if (accessToken) {
      const payload = {
        //id: _id,
        data: {},
        token: accessToken
      }
      itemQuery(payload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // is loading
  if (['', 'initial', 'loading'].includes(status)) {
    return <Preloader show={true} />
  }

  if (['fail', 'error'].includes(status)) {
    return (
      <div>
        <Alerts alerts={error} />
      </div>
    )
  }

  return <Form />
}

export default Component
