import { combineReducers } from 'redux'
import _ from 'lodash'

import * as billingsList from './billingsList'
import * as getBilling from './getBilling'
import * as createBilling from './createBilling'
import * as editBilling from './editBilling'
import * as deleteBilling from './deleteBilling'
import * as billingsListTypeahead from './billingsListTypeahead'
import * as billingStats from './billingStats'

const slices = {
  billingsList,
  getBilling,
  createBilling,
  editBilling,
  deleteBilling,
  billingsListTypeahead,
  billingStats
}

const billingTypes = _.mapValues(slices, (s) => s.slice.types)
const billingActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const billingSagas = _(slices).values().map('slice.sagas').flatten().value()
const billingSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { billingTypes, billingActions, billingSagas, billingSelectors }

export default combineReducers(reducers)
