// ==============================|| OVERRIDES - ICON BUTTON ||============================== //

export default function ButtonBase() {
  return {
    MuiButtonBase: {
      styleOverrides: {
        root: {}
      }
    }
  }
}
