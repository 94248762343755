import PropTypes from 'prop-types'

// material-ui
import { useTheme } from '@mui/material/styles'
import { AppBar, Container, IconButton, Toolbar, useMediaQuery } from '@mui/material'

// project import
import AppBarStyled from './AppBarStyled'
import HeaderContent from './HeaderContent'
import HorizontalMenu from '../HorizontalMenu'

// assets
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded'

const Header = ({ open, handleDrawerToggle }) => {
  const theme = useTheme()
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'))

  const iconBackColor = 'grey.100'
  const iconBackColorOpen = 'grey.200'

  // common header
  const mainHeader = (
    <Container disableGutters maxWidth={matchDownMD ? 'false' : 'xl'} sx={{ px: () => (matchDownMD ? 1 : 2) }}>
      {/* <Container maxWidth="false" disableGutters={matchDownMD} sx={{ bgcolor: 'background.default', px: 1 }}> */}
      {/* <Container maxWidth={matchDownMD ? 'false' : 'xl'} disableGutters> */}
      <Toolbar disableGutters>
        {matchDownMD && (
          <IconButton
            disableRipple
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            color="secondary"
            sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 }, p: 1.25, mr: 1 }}
          >
            {!open ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
          </IconButton>
        )}
        <HeaderContent />
      </Toolbar>
      {/* </Container> */}
    </Container>
  )

  // app-bar params
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`
      // boxShadow: theme.customShadows.z1
    }
  }

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
          <HorizontalMenu />
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  )
}

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
}

export default Header
