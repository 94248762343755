import { combineReducers } from 'redux'
import _ from 'lodash'

import * as healthReviewsList from './list'
import * as healthReviewsListLookup from './listLookup'
import * as getHealthReview from './get'
import * as getResidentPopulate from './getResidentPopulate'
import * as createHealthReview from './create'
import * as editHealthReview from './edit'
import * as deleteHealthReview from './delete'

const slices = {
  healthReviewsList,
  healthReviewsListLookup,
  getHealthReview,
  getResidentPopulate,
  createHealthReview,
  editHealthReview,
  deleteHealthReview
}

const healthReviewTypes = _.mapValues(slices, (s) => s.slice.types)
const healthReviewActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const healthReviewSagas = _(slices).values().map('slice.sagas').flatten().value()
const healthReviewSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { healthReviewTypes, healthReviewActions, healthReviewSagas, healthReviewSelectors }

export default combineReducers(reducers)
