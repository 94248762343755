import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded'
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded'
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import HomeWorkRoundedIcon from '@mui/icons-material/HomeWorkRounded'
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded'

const support = {
  id: 'administration',
  title: 'Administration',
  type: 'group',
  children: [
    {
      id: 'billing',
      title: 'Billing',
      type: 'item',
      icon: AttachMoneyRoundedIcon,
      url: '/billing',
      requirements: {
        componentId: 'NavItem__Billing',
        auth: true,
        endpoints: [{ method: 'GET', path: '/user' }]
      }
    },
    {
      id: 'reports',
      title: 'Reports',
      type: 'item',
      icon: TrendingUpRoundedIcon,
      url: '/reports',
      requirements: {
        componentId: 'NavItem__Reports',
        auth: true,
        endpoints: [{ method: 'GET', path: '/user' }]
      }
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'collapse',
      icon: SettingsRoundedIcon,
      requirements: {
        componentId: 'NavItem__Settings',
        auth: true,
        endpoints: [{ method: 'GET', path: '/user' }]
      },
      children: [
        {
          id: 'users',
          title: 'Users',
          type: 'item',
          url: '/users',
          icon: PeopleRoundedIcon
        },
        {
          id: 'facilities',
          title: 'Facilities',
          type: 'item',
          url: '/facilities',
          icon: HomeWorkRoundedIcon
        },
        {
          id: 'companies',
          title: 'Companies',
          type: 'item',
          url: '/companies',
          icon: BusinessCenterRoundedIcon
        }
      ]
    }
  ]
}

export default support
