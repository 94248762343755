const name = 'billsListGrid'

//initialState
const initialState = {
  status: 'initial',
  data: {
    filterModel: {
      // items: [{ id: 1, columnField: 'isDeleted', operatorValue: 'is', value: 'false' }], // apply default datagrid filter
      items: [],
      linkOperator: undefined
    },
    sortModel: [
      // { field: 'residentLocationFacilityName', sort: 'asc' },
      { field: 'residentFullName', sort: 'asc' }
    ],
    selectionModel: []
  }
}

// Actions
const types = {
  ACTION_SET: `store/${name}/SET`,
  ACTION_RESET: `store/${name}/RESET`
}

// Reducer
const reducers = {}
reducers[name] = function (state = initialState, action) {
  switch (action.type) {
    case types.ACTION_SET:
      return { ...state, status: 'success', data: { ...state.data, ...action.payload } }
    case types.ACTION_RESET:
      return { ...state, ...initialState }
    default:
      return state
  }
}

//Action creators
const actions = {}
actions[name] = (payload) => ({ type: types.ACTION_SET, payload, actionId: payload?.actionId ?? Date.now() })
actions[`${name}Reset`] = (payload) => ({ type: types.ACTION_RESET, payload, actionId: payload?.actionId ?? Date.now() })

export const slice = {
  types,
  actions,
  sagas: [],
  selectors: {}
}
export default reducers
