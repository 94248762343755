import SpaRoundedIcon from '@mui/icons-material/SpaRounded'
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded'
import MedicationLiquidRoundedIcon from '@mui/icons-material/MedicationLiquidRounded'
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = {
  id: 'services',
  title: 'Services',
  type: 'group',
  children: [
    {
      id: 'residents',
      title: 'Residents',
      type: 'item',
      icon: PeopleRoundedIcon,
      url: '/residents',
      requirements: {
        componentId: 'NavItem__Residents',
        auth: true,
        endpoints: [{ method: 'GET', path: '/user' }]
      }
    },
    {
      id: 'care-plan',
      title: 'Care Plan',
      type: 'collapse',
      icon: SpaRoundedIcon,
      requirements: {
        componentId: 'NavItem__CarePlan',
        auth: true,
        endpoints: [{ method: 'GET', path: '/user' }]
      },
      children: [
        {
          id: 'services',
          title: 'Services',
          type: 'item',
          url: 'care-plan/services',
          icon: SupervisedUserCircleRoundedIcon
        },
        {
          id: 'medications',
          title: 'Medications',
          type: 'item',
          url: 'care-plan/medications',
          icon: MedicationLiquidRoundedIcon
        }
      ]
    }
  ]
}

export default support
