const config = {
  defaultPath: '/',
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
}

export default config
export const drawerWidth = 260
