// project import
//import pages from './pages'
//import support from './support'
import dashboard from './dashboard'
import services from './services'
import administration from './administration'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // items: [dashboard, pages, services, support]
  items: [dashboard, services, administration]
}

export default menuItems
