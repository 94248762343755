import { combineReducers } from 'redux'
import _ from 'lodash'

import * as residentsList from './residentsList'
import * as getResident from './getResident'
import * as getResidentProfile from './getResidentProfile'
import * as createResident from './createResident'
import * as editResident from './editResident'
import * as deleteResident from './deleteResident'

const slices = {
  residentsList,
  createResident,
  getResident,
  getResidentProfile,
  editResident,
  deleteResident
}

const residentTypes = _.mapValues(slices, (s) => s.slice.types)
const residentActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const residentSagas = _(slices).values().map('slice.sagas').flatten().value()
const residentSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { residentTypes, residentActions, residentSagas, residentSelectors }

export default combineReducers(reducers)
