import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { appActions as Actions } from '../../store/app'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, useMediaQuery, Container, Grid } from '@mui/material'

// project import
import Drawer from './Drawer'
import Header from './Header'
import Breadcrumbs from '../../components/@extended/Breadcrumbs'

const MainLayout = () => {
  const theme = useTheme()
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'))

  const actionName = 'menu'
  const stateName = 'app_data'
  const actionStateSelector = (state) => state[stateName][actionName]
  const dispatch = useDispatch()
  const doAction = (payload) => dispatch(Actions[actionName](payload))
  const itemRes = useSelector(actionStateSelector)
  const drawerOpen = itemRes?.data?.drawerOpen ?? false

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen)
  const handleDrawerToggle = () => {
    setOpen(!open)
    const payload = { drawerOpen: !open }
    doAction(payload)
  }

  // set media wise responsive drawer
  useEffect(() => {
    //setOpen(!matchDownMD)
    //const payload = { drawerOpen: !matchDownMD }
    //doAction(payload)

    if (!open) {
      return
    }

    if (matchDownMD) {
      return
    }

    // close the drawer for desktops
    setOpen(false)
    const payload = { drawerOpen: false }
    doAction(payload)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMD])

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen])

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        minHeight: 460,
        maxHeight: 1080,
        // minWidth setting
        // 360 general rule
        // for modals: mx: 32, mt: 50, mb: 32; px: 24, py: 16, borderTop : 1px, borderBottom: 1px
        minWidth: 360,
        // overflow: 'scroll',
        // overflowY: 'auto',
        // boxSizing: 'content-box',
        flexDirection: 'column',
        bgcolor: 'background.default'
      }}
    >
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ flexGrow: 1, marginTop: { xs: '61px', md: '111px' } }}>
        <Container maxWidth="xl" sx={{ px: { md: 5, height: '100%', display: 'flex', flexDirection: 'column' } }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ pt: 2, pb: 1 }}>
                <Breadcrumbs />
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ flex: 1, pb: 2 }}>
            <Grid item xs={12}>
              <Outlet />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default MainLayout
