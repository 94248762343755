// ==============================|| OVERRIDES - DATA GRID ||============================== //

export default function Card(theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        row: {
          '&:hover': {
            // @TODO replace with theme palette color
            backgroundColor: '#B7E7FF'
          }
        },
        columnHeaders: {
          backgroundColor: theme.palette.background.default,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)'
        }
      }
    }
  }
}
