import { combineReducers } from 'redux'
import _ from 'lodash'

import * as billItemsList from './list'
import * as getBillItem from './get'
import * as createBillItem from './create'
import * as editBillItem from './edit'
import * as deleteBillItem from './delete'

const slices = {
  billItemsList,
  getBillItem,
  createBillItem,
  editBillItem,
  deleteBillItem
}

const billItemTypes = _.mapValues(slices, (s) => s.slice.types)
const billItemActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const billItemSagas = _(slices).values().map('slice.sagas').flatten().value()
const billItemSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { billItemTypes, billItemActions, billItemSagas, billItemSelectors }

export default combineReducers(reducers)
