import PropTypes from 'prop-types'
import { useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, ListItemButton, ListItemIcon, ListItemText, Typography, Menu } from '@mui/material'

import NavItem from './NavItem'

import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

// ==============================|| NAVIGATION - LIST COLLAPSE ||============================== //

const NavCollapse = ({ menu, level }) => {
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const borderIcon = level === 1 ? <Box sx={{ borderTop: 1 }} /> : false
  const Icon = menu.icon
  const menuIcon = menu.icon ? <Icon style={{ fontSize: '1.25rem' }} /> : borderIcon
  const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.accent'

  return (
    <>
      <ListItemButton disableRipple onClick={handleClick} sx={open ? { flexGrow: 0, background: 'rgba(0, 0, 0, 0.04)' } : { flexGrow: 0 }}>
        {menuIcon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: textColor
            }}
          >
            {menuIcon}
          </ListItemIcon>
        )}
        <ListItemText
          sx={{ flexGrow: 0 }}
          primary={
            <Typography variant="h6" color={textColor}>
              {menu.title}
            </Typography>
          }
          secondary={
            menu.caption && (
              <Typography variant="caption" color="secondary">
                {menu.caption}
              </Typography>
            )
          }
        />
        {open ? <ExpandLessRoundedIcon sx={{ minWidth: 28 }} /> : <ExpandMoreRoundedIcon sx={{ minWidth: 28 }} />}
      </ListItemButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menu.children?.map((item) => {
          switch (item.type) {
            case 'collapse':
              return <NavItem key={item.id} item={item} style={{ minWidth: '150px' }} />
            case 'item':
              return <NavItem key={item.id} item={item} style={{ minWidth: '150px' }} />
            default:
              return (
                <Typography key={item.id} variant="h6" color="error" align="center">
                  Fix - Collapse or Item
                </Typography>
              )
          }
        })}
      </Menu>
    </>
  )
}

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number
}

export default NavCollapse
