import { combineReducers } from 'redux'
import _ from 'lodash'

import * as billsList from './billsList'
import * as overpaidBillsWarning from './overpaidBillsWarning'
import * as getBill from './getBill'
import * as createBill from './createBill'
import * as editBill from './editBill'
import * as deleteBill from './deleteBill'
import * as billsListTypeahead from './billsListTypeahead'

const slices = {
  billsList,
  overpaidBillsWarning, // billsList copy
  getBill,
  createBill,
  editBill,
  deleteBill,
  billsListTypeahead
}

const billTypes = _.mapValues(slices, (s) => s.slice.types)
const billActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const billSagas = _(slices).values().map('slice.sagas').flatten().value()
const billSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { billTypes, billActions, billSagas, billSelectors }

export default combineReducers(reducers)
