import { combineReducers } from 'redux'
import _ from 'lodash'

import * as menu from './menu'
import * as snackbar from './snackbar'
import * as reloadPages from './reloadPages'
import * as currentFacility from './currentFacility'
import * as billingPageFilter from './billing/pageFilter'
import * as residentsListFilters from './residentsList/filter'
import * as residentsFloorPlanFilters from './residentsFloorPlan/filter'
import * as billsListFilters from './billsList/filter'
import * as rentRollsListFilters from './rentRollsList/filter'
import * as trustTransactionsListFilters from './trustTransactionsList/filter'
import * as healthReviewsListFilters from './healthReviewsList/filter'
import * as trustFacilityBalancesListFilters from './trustFacilityBalancesList/filter'
import * as paymentsListFilters from './paymentsList/filter'
import * as paymentsBatchListFilters from './paymentsBatchList/filter'
import * as paymentsBatchItemsListFilters from './paymentsBatchItemsList/filter'

const slices = {
  menu,
  snackbar,
  reloadPages,
  currentFacility,
  billingPageFilter,
  residentsListFilters,
  residentsFloorPlanFilters,
  billsListFilters,
  rentRollsListFilters,
  trustTransactionsListFilters,
  healthReviewsListFilters,
  trustFacilityBalancesListFilters,
  paymentsListFilters,
  paymentsBatchListFilters,
  paymentsBatchItemsListFilters
}

const appTypes = _.mapValues(slices, (s) => s.slice.types)
const appActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const appSagas = _(slices).values().map('slice.sagas').flatten().value()
const appSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { appTypes, appActions, appSagas, appSelectors }

export default combineReducers(reducers)
