import { combineReducers } from 'redux'
import _ from 'lodash'

import * as paymentsBatchItemsList from './list'
import * as getPaymentBatchItem from './get'
import * as createPaymentBatchItem from './create'
import * as editPaymentBatchItem from './edit'
import * as deletePaymentBatchItem from './delete'

const slices = {
  paymentsBatchItemsList,
  getPaymentBatchItem,
  createPaymentBatchItem,
  editPaymentBatchItem,
  deletePaymentBatchItem
}

const paymentBatchItemTypes = _.mapValues(slices, (s) => s.slice.types)
const paymentBatchItemActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const paymentBatchItemSagas = _(slices).values().map('slice.sagas').flatten().value()
const paymentBatchItemSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { paymentBatchItemTypes, paymentBatchItemActions, paymentBatchItemSagas, paymentBatchItemSelectors }

export default combineReducers(reducers)
