import { Stack, Box } from '@mui/material'

import ProfileMini from 'components/ProfileMini'
import Notification from './Notification'
import FacilityDropdown from './FacilityDropdown'
import { Typography } from '@mui/material'

const HeaderContent = () => {
  // TODO  - user fetched partner data here
  const partner = {
    name: 'CCAG',
    logo: `${process.env.REACT_APP_API_URL}/uploads/638ff6f4429e8e5b2c6d2a0c`
  }
  return (
    <Stack px={{ md: 3 }} pr={{ xs: 1 }} flex={1} direction="row" justifyContent="flex-end">
      <Stack flex={1} direction="row">
        {partner?.logo ? (
          <img alt={partner?.name} src={partner?.logo} style={{ height: '44px', width: 'auto' }} />
        ) : (
          <Typography sx={{ fontSize: '2em' }}>{partner?.name}</Typography>
        )}
        <Box sx={{ display: 'grid' }}>
          <FacilityDropdown />
        </Box>
      </Stack>
      <Stack flex={1} flexGrow={0} direction="row" justifyContent="flex-end">
        <Notification />
        <ProfileMini />
      </Stack>
    </Stack>
  )
}

export default HeaderContent
