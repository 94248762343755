import { useState } from 'react'
import CheckPermissions from 'components/permissions/CheckPermissions'

const Component = (props) => {
  // this component requirements
  const [componentId] = useState(`ProfileMini_${Date.now()}`)
  const requirements = {
    componentId,
    auth: true,
    endpoints: [{ method: 'GET', path: '/user' }]
  }

  return <CheckPermissions {...props} requirements={requirements} />
}

export default Component
