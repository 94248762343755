// third-party
import { merge } from 'lodash'

// project import
import Card from './Card'
import Chip from './Chip'
import DataGrid from './DataGrid'
import Dialog from './Dialog'
import ButtonBase from './ButtonBase'
import Button from './Button'
import IconButton from './IconButton'

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme) {
  return merge(Card(theme), Chip(theme), DataGrid(theme), Dialog(theme), ButtonBase(theme), Button(theme), IconButton(theme))
}
