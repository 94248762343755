import { all } from 'redux-saga/effects'

import { userSagas } from './users'
import { roleSagas } from './roles'
import { facilitySagas } from './facilities'
import { suiteSagas } from './suites'
import { residentSagas } from './residents'
import { partnerSagas } from './partners'
import { settingsSagas } from './settings'
import { apiKeySagas } from './apiKeys'
import { appSagas } from './app'
import { censusSagas } from './censuses'
import { rateSagas } from './rates'
import { billingSagas } from './billings'
import { billSagas } from './bills'
import { billItemSagas } from './billItems'
import { rentRollSagas } from './rentRolls'
import { trustTransactionSagas } from './trustTransactions'
import { healthReviewSagas } from './healthReviews'
import { trustFacilityBalanceSagas } from './trustFacilityBalances'
import { trustBalanceSagas } from './trustBalances'
import { paymentSagas } from './payments'
import { paymentBatchSagas } from './paymentsBatch'
import { paymentBatchItemSagas } from './paymentsBatchItems'

export default function* rootSaga() {
  yield all([
    ...userSagas,
    ...roleSagas,
    ...facilitySagas,
    ...suiteSagas,
    ...residentSagas,
    ...partnerSagas,
    ...settingsSagas,
    ...apiKeySagas,
    ...appSagas,
    ...censusSagas,
    ...rateSagas,
    ...billingSagas,
    ...billSagas,
    ...billItemSagas,
    ...rentRollSagas,
    ...trustTransactionSagas,
    ...healthReviewSagas,
    ...trustFacilityBalanceSagas,
    ...trustBalanceSagas,
    ...paymentSagas,
    ...paymentBatchSagas,
    ...paymentBatchItemSagas
  ])
}
