import { useLocation } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@mui/material'
import { Breadcrumbs, Stack } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'

import { useDispatch } from 'react-redux'
import { appActions as Actions } from '../../store/app'
import { useNavigate } from 'react-router-dom'

import _ from 'lodash'

function Component() {
  const location = useLocation()
  const pathItems = _.compact(location.pathname.split('/'))

  const navigate = useNavigate()
  const actionName = 'menu'
  const dispatch = useDispatch()
  const doAction = (payload) => dispatch(Actions[actionName](payload))
  const itemHandler = (id) => {
    const payload = { openItem: [id] }
    doAction(payload)
    navigate(id)
  }

  const generateLink = (item) => {
    let path = ''
    for (let i = 0; i < pathItems.length; i++) {
      path = `${path}/${pathItems[i]}`
      if (pathItems[i] === item) {
        break
      }
    }
    return path
  }

  return (
    <>
      <Stack>
        <Breadcrumbs separator={`/`} aria-label="breadcrumbs">
          <Link
            onClick={() => itemHandler('/')}
            component={RouterLink}
            to="/"
            color="text.primary"
            style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
          >
            <HomeIcon sx={{ mr: 0.25, mb: 0.25, fontSize: '18px' }} />
            <span>Home</span>
          </Link>

          {pathItems.map((item) => {
            let linkItem = _.lowerCase(item) // resolves names with dashes and underscores like mail-server
            linkItem = _.upperFirst(linkItem) // applies upper first letter

            // it's an ObjectID
            if (item.match(/^[0-9a-fA-F]{24}$/)) {
              linkItem = item
            }

            return (
              <Link
                key={item}
                onClick={() => itemHandler(item)}
                component={RouterLink}
                to={generateLink(item)}
                color="text.primary"
                style={{ textDecoration: 'none' }}
              >
                {linkItem}
              </Link>
            )
          })}
        </Breadcrumbs>
      </Stack>
    </>
  )
}

export default Component
