import { combineReducers } from 'redux'
import _ from 'lodash'

import * as facilitiesList from './facilitiesList'
import * as getFacility from './getFacility'
import * as createFacility from './createFacility'
import * as editFacility from './editFacility'
import * as deleteFacility from './deleteFacility'
import * as facilityListTypeahead from './facilityListTypeahead'

const slices = {
  facilitiesList,
  getFacility,
  createFacility,
  editFacility,
  deleteFacility,
  facilityListTypeahead
}

const facilityTypes = _.mapValues(slices, (s) => s.slice.types)
const facilityActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const facilitySagas = _(slices).values().map('slice.sagas').flatten().value()
const facilitySelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { facilityTypes, facilityActions, facilitySagas, facilitySelectors }

export default combineReducers(reducers)
