// ==============================|| OVERRIDES - CARD ||============================== //

export default function Card() {
  return {
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h3' }
      }
    }
  }
}
