import { lazy } from 'react'

import Loadable from 'components/Loadable'
import MinimalLayout from 'layout/MinimalLayout'

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')))
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')))
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')))

const MaintenanceRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '404',
      element: <MaintenanceError />
    },
    {
      path: '500',
      element: <MaintenanceError500 />
    },
    {
      path: 'under-construction',
      element: <MaintenanceUnderConstruction />
    }
  ]
}

export default MaintenanceRoutes
