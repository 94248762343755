import { combineReducers } from 'redux'
import _ from 'lodash'

import * as trustTransactionsList from './list'
import * as getTrustTransaction from './get'
import * as createTrustTransaction from './create'
import * as editTrustTransaction from './edit'
import * as deleteTrustTransaction from './delete'

const slices = {
  trustTransactionsList,
  getTrustTransaction,
  createTrustTransaction,
  editTrustTransaction,
  deleteTrustTransaction
}

const trustTransactionTypes = _.mapValues(slices, (s) => s.slice.types)
const trustTransactionActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const trustTransactionSagas = _(slices).values().map('slice.sagas').flatten().value()
const trustTransactionSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { trustTransactionTypes, trustTransactionActions, trustTransactionSagas, trustTransactionSelectors }

export default combineReducers(reducers)
