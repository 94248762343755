import { useEffect, useCallback } from 'react'

import { Select, MenuItem, FormControl, Input } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
// import { useQuery } from 'helpers/useQuery'
import { getCookie } from 'helpers/cookies'
import { facilityActions } from 'store/facilities'
import { appActions } from 'store/app'
import _ from 'lodash'

const Component = () => {
  const dispatch = useDispatch()
  // const { query, setQuery, removeQuery, history } = useQuery()

  const fieldName = 'name'
  const idName = '_id'
  const extraOptions = { [fieldName]: 'Headquarter', [idName]: 'all' }

  const stateNameApp = 'app_data'
  const actionName = 'currentFacility'
  const doAction = (payload) => dispatch(appActions[actionName](payload))
  const doActionReset = () => dispatch(appActions[`${actionName}Reset`]())
  const currentFacility = useSelector((state) => state[stateNameApp][actionName])
  const facilityId = _.get(currentFacility, 'data.id', 'all')
  const itemHandler = (id) => {
    const payload = { id: id }
    doAction(payload)
  }

  // Get facility List
  const stateNameFacilityList = 'facilities_data'
  const actionNameFacilityList = 'facilitiesList'

  // const getFacilitiesList = (payload) => dispatch(facilityActions[actionNameFacilityList](payload))
  const getFacilitiesList = useCallback(
    (payload) => {
      dispatch(facilityActions[actionNameFacilityList](payload))
    },
    [dispatch]
  )
  const resFacilitiesList = useSelector((state) => state[stateNameFacilityList][actionNameFacilityList])
  // const resFacilitiesList = useCallback(() => {
  //   dispatch(facilityActions[`${actionNameFacilityList}Reset`]())
  // }, [dispatch])
  const status = resFacilitiesList?.status ?? ''
  const error = resFacilitiesList?.data?.error ?? 'Error'
  const facilitiesList = resFacilitiesList?.data?.items ?? []
  let options = facilitiesList.length > 0 ? _.map(facilitiesList, ({ _id, name }) => ({ _id, name })) : []

  // useEffect(() => {
  //   const facilityId = currentFacility.data.id
  //   if (facilityId) {
  //     // setQuery({ facility: facilityId })
  //   }
  //   if (!facilityId) {
  //     // removeQuery('facility')
  //   }
  // }, [currentFacility, removeQuery, setQuery])

  useEffect(() => {
    const accessToken = getCookie(`${process.env.REACT_APP_WEBSITE_NAME}accessToken`)
    if (!accessToken) {
      // document.location.reload()
      return
    }

    const payload = {
      data: {
        limit: 100,
        offset: 0,
        search: ''
      },
      token: accessToken
    }
    getFacilitiesList(payload)

    // return () => {
    //   removeQuery('facility')
    // }
  }, [getFacilitiesList])

  // is loading
  const isLoading = ['', 'initial', 'loading'].includes(status)

  if (['fail', 'error'].includes(status)) {
    console.log('Error loading options for the field: ', actionName, 'Details: ', error)
    options = [{ value: '', label: 'Error loading options' }]
  }

  const handleChange = (e) => {
    const facility = e.target.value

    if (facility === 'all') {
      // removeQuery('facility')
      return doActionReset()
    }

    // setQuery({ facility: facility })
    itemHandler(facility)
  }

  return (
    <FormControl variant="standard" size="medium" sx={{ mx: 2 }}>
      <Select
        id="facility-selector"
        value={facilityId}
        disabled={isLoading}
        disableUnderline
        onChange={handleChange}
        sx={{
          // '& #facility-selector': (theme) => {
          '& .MuiInputBase-input': (theme) => {
            return {
              ...theme.typography.h6
            }
          }
        }}
        input={<Input variant="standard" sx={{ padding: 20 }} />}
        style={{ height: 44, minWidth: 50 }}
      >
        {/* @TODO add this option only for eligible users */}
        <MenuItem key={extraOptions._id} value={extraOptions._id} disabled={extraOptions.disabled}>
          {extraOptions.name}
        </MenuItem>
        {options.map((facility) => (
          <MenuItem key={facility._id} value={facility._id} disabled={facility.disabled}>
            {facility.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default Component
