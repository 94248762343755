import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { appActions as Actions } from 'store/app'

export function useNavigateCustom() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const actionName = 'menu'
  const doAction = (payload) => dispatch(Actions[actionName](payload))
  const navigateCustom = (id) => {
    const payload = { openItem: [`${id}`] }
    doAction(payload)
    navigate(`${id}`)
  }
  return navigateCustom
}
