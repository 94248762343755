import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// ==============================|| NAVIGATION - SCROLL TO TOP ||============================== //

const ScrollTop = ({ children }) => {
  const { pathname, state } = useLocation()
  useEffect(() => {
    if (state?.preventScrollReset) {
      return
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [pathname, state?.preventScrollReset])

  return children || null
}

ScrollTop.propTypes = {
  children: PropTypes.node
}

export default ScrollTop
