import { AppBar, Container, Typography, Stack } from '@mui/material'
import _ from 'lodash'
import { useSelector } from 'react-redux'

// project import
import NavGroup from './NavGroup'
import menuItem from 'menu-items'

const Navigation = () => {
  const checkPermissionsCollectionRes = useSelector((state) => state.users_data.checkPermissionsCollection.data)
  const navItemPermissions = _.pickBy(checkPermissionsCollectionRes, (v, k) => {
    if (!k.startsWith('NavItem__')) {
      return false
    }

    if (!v?.data?.hasRequestedPermissions) {
      return false
    }

    return true
  })

  const navGroups = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        )
    }
  })

  //const count1stLevelNavItems = _(menuItem.items).map('children').flatten().value()
  const count1stLevelNavItems = Object.keys(navItemPermissions).length

  return (
    <Stack
      direction="row"
      justifyContent={count1stLevelNavItems <= 4 ? 'left' : 'space-between'}
      alignItems="center"
      // spacing={2}
      sx={{ height: 50 }}
    >
      {navGroups}
    </Stack>
  )
}

const HorizontalMenu = () => {
  return (
    <AppBar position="static" color="primary">
      <Container maxWidth="xl">
        <Navigation />
      </Container>
    </AppBar>
  )
}

export default HorizontalMenu
