import { legacy_createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware()

  const envName = process.env.NODE_ENV
  const composeEnhancers =
    ((envName === 'development' || envName === 'staging') &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose

  return {
    ...legacy_createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware))),
    runSaga: sagaMiddleware.run(rootSaga)
  }
}

export default configureStore
