import { combineReducers } from 'redux'
import _ from 'lodash'

import * as apiKeysList from './apiKeysList'
import * as getApiKey from './getApiKey'
import * as createApiKey from './createApiKey'
import * as editApiKey from './editApiKey'
import * as deleteApiKey from './deleteApiKey'

const slices = {
  apiKeysList,
  createApiKey,
  getApiKey,
  editApiKey,
  deleteApiKey
}

const apiKeyTypes = _.mapValues(slices, (s) => s.slice.types)
const apiKeyActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const apiKeySagas = _(slices).values().map('slice.sagas').flatten().value()
const apiKeySelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { apiKeyTypes, apiKeyActions, apiKeySagas, apiKeySelectors }

export default combineReducers(reducers)
