import { lazy } from 'react'

import Loadable from 'components/Loadable'
import MainLayout from 'layout/MainLayout'

const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')))
const ResidentsDefault = Loadable(lazy(() => import('pages/residents')))
const SamplePage = Loadable(lazy(() => import('pages/samplePage')))
const UsersPage = Loadable(lazy(() => import('pages/users')))
const UserPage = Loadable(lazy(() => import('pages/users/user')))
const UserEditPage = Loadable(lazy(() => import('pages/users/userEdit')))
const UserCreatePage = Loadable(lazy(() => import('pages/users/userCreate')))
const MyProfilePage = Loadable(lazy(() => import('pages/myProfile')))
const MyProfileEditPage = Loadable(lazy(() => import('pages/myProfile/profileEdit')))

const ResidentCreate = Loadable(lazy(() => import('pages/residents/sections/Resident/residentCreate')))
const ResidentProfile = Loadable(lazy(() => import('pages/residents/residentProfile')))

const BillingPage = Loadable(lazy(() => import('pages/billing')))

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'residents',
      element: <ResidentsDefault />
    },
    {
      path: 'residents/:_id',
      element: <ResidentProfile />,
      children: ['', ':tabId', ':tabId/:formName', ':tabId/:tabItemId/:formName'].map((p) => ({
        path: p,
        element: <ResidentProfile />
      }))
    },
    {
      path: 'resident/create',
      element: <ResidentCreate />
    },
    {
      path: 'care-plan',
      children: [
        {
          path: 'services',
          element: <SamplePage title={'services'} />
        },
        {
          path: 'medications',
          element: <SamplePage title={'medications'} />
        }
      ]
    },
    {
      path: 'billing',
      element: <BillingPage />,
      children: ['', ':tabId', ':tabId/:tabAction', ':tabId/:tabItemId/:tabAction'].map((p) => ({
        path: p,
        element: <BillingPage />
      }))
    },
    {
      path: 'reports',
      element: <SamplePage title={'reports'} />
    },
    {
      path: 'sample-page',
      element: <SamplePage title={'sample-page'} />
    },
    {
      path: 'users',
      element: <UsersPage />
    },
    {
      path: 'users/create',
      element: <UserCreatePage />
    },
    {
      path: 'users/:_id',
      element: <UserPage />
    },
    {
      path: 'users/:_id/edit',
      element: <UserEditPage />
    },
    {
      path: 'profile',
      element: <MyProfilePage />
    },
    {
      path: 'profile/edit',
      element: <MyProfileEditPage />
    },
    {
      path: 'companies',
      element: <SamplePage title={'companies'} />
    },
    {
      path: 'facilities',
      element: <SamplePage title={'facilities'} />
    }
  ]
}

export default MainRoutes
