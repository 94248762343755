import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// material-ui
import { ButtonBase } from '@mui/material'

// project import
import SpaRoundedIcon from '@mui/icons-material/SpaRounded'
import config from 'config'

const LogoSection = ({ sx, to }) => (
  <ButtonBase disableRipple component={Link} to={!to ? config.defaultPath : to} sx={sx}>
    <SpaRoundedIcon />
  </ButtonBase>
)

LogoSection.propTypes = {
  sx: PropTypes.object,
  to: PropTypes.string
}

export default LogoSection
