import { Suspense } from 'react'
import Loader from './Loader'

const Loadable = (Component) => {
  const component = (props) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  )
  return component
}

export default Loadable
