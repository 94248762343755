// ==============================|| OVERRIDES - ICON BUTTON ||============================== //

export default function IconButton(theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem'
        },
        colorPrimary: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.text.white,

          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.text.white
          }
        },
        colorError: {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.text.white,

          '&:hover': {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.text.white
          }
        },
        sizeMedium: {
          fontSize: '1rem',
          padding: '0.75em'
        }
      }
    }
  }
}
