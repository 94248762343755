/* usage example 1
  import { useDispatch } from 'react-redux'
  import { appActions } from 'store/app'
  
  ...

  const dispatch = useDispatch()
  const setSnackbar = (payload) => dispatch(appActions.snackbar(payload))
  
  const payload = {
    open: true,
    message: 'Resident updated successfully.',
    variant: 'alert',
    // variant: 'default',
    alert: {
      // variant: 'outlined',
      color: 'success'
    },
    // close: false,
    anchorOrigin: { vertical: 'top', horizontal: 'center' },
    transition: 'SlideLeft',
    // showBackDrop: true,
    navLink: {
      label: 'Go to resident profile',
      route: `/residents/${profile._id}`
    }
  }
  setSnackbar(payload)
*/

/* usage example 
import { useSnackbar } from 'helpers/useSnackbar'

const { setSnackbar } = useSnackbar()
const handleAddSnackbar = () => {
  const payload = {
    message: 'Resident updated successfully.',
    navLink: {
      label: 'Go to resident profile',
      route: `/residents/${profile._id}`
    }
  }

  setSnackbar(payload)
}
*/

import { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { appActions } from 'store/app'

// material-ui
import { Alert, Fade, Grow, Slide, IconButton, Stack, Typography, Backdrop, Link, Snackbar } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useNavigateCustom } from 'helpers/useNavigateCustom'

const BackdropStyled = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff'
}))

// assets
import CloseIcon from '@mui/icons-material/Close'
// import CloseOutlined from '@ant-design/icons/CloseOutlined'
// import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'

// animation function
function TransitionSlideLeft(props) {
  return <Slide {...props} direction="left" />
}

function TransitionSlideUp(props) {
  return <Slide {...props} direction="up" />
}

function TransitionSlideRight(props) {
  return <Slide {...props} direction="right" />
}

function TransitionSlideDown(props) {
  return <Slide {...props} direction="down" />
}

function GrowTransition(props) {
  return <Grow {...props} />
}

// animation options
const animation = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade
}

// ==============================|| SNACKBAR ||============================== //

const Component = () => {
  const navigateCustom = useNavigateCustom()

  const dispatch = useDispatch()
  const stateName = 'app_data'
  const actionName = 'snackbar'
  const removeSnackbar = useCallback(
    (payload) => {
      dispatch(appActions.snackbar(payload))
    },
    [dispatch]
  )
  const snackbar = useSelector((state) => state[stateName][actionName])
  const item = snackbar.data

  const handleClose = useCallback(
    (event, reason) => {
      if (reason === 'clickaway' && !item.showBackDrop) {
        return
      }

      const payload = {
        open: false,
        showBackDrop: false
      }

      removeSnackbar(payload)
    },
    [item.showBackDrop, removeSnackbar]
  )

  const handleLinkClick = useCallback(() => {
    navigateCustom(item.navLink?.route)
    handleClose()
  }, [handleClose, item.navLink?.route, navigateCustom])

  const SnackbarMemoized = useMemo(() => {
    if (item.variant === 'alert') {
      const { anchorOrigin, alert, close, message, open, transition } = item
      return (
        <>
          <Snackbar
            TransitionComponent={animation[transition]}
            anchorOrigin={anchorOrigin}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              variant={alert.variant}
              color={alert.color}
              action={
                <>
                  {close !== false && (
                    <IconButton size="small" aria-label="close" variant="contained" onClick={handleClose}>
                      <CloseIcon />
                      {/* <CloseOutlined /> */}
                      {/* <CloseCircleOutlined /> */}
                    </IconButton>
                  )}
                </>
              }
              sx={{ ...(alert?.variant === 'outlined' && { bgcolor: 'grey.0' }) }}
            >
              <Stack direction="row" spacing={1}>
                <Typography>{message}</Typography>
                {item.navLink && (
                  <Link component="button" underline="always" onClick={handleLinkClick}>
                    <Typography variant="body1">{item.navLink.label}</Typography>
                  </Link>
                )}
              </Stack>
            </Alert>
          </Snackbar>
          <BackdropStyled open={item.showBackDrop} />
        </>
      )
    }

    if (item.variant === 'default') {
      const { anchorOrigin, message, open, transition } = item
      return (
        <Snackbar
          anchorOrigin={anchorOrigin}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={message}
          TransitionComponent={animation[transition]}
          action={
            <>
              {/* <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
              </Button> */}
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon />
                {/* <CloseOutlined /> */}
              </IconButton>
            </>
          }
        />
      )
    }
  }, [handleClose, handleLinkClick, item])

  return <>{SnackbarMemoized}</>
}

export default Component
