import { combineReducers } from 'redux'
import _ from 'lodash'

import * as censusesList from './censusesList'
import * as getCensus from './getCensus'
import * as createCensus from './createCensus'
import * as editCensus from './editCensus'
import * as deleteCensus from './deleteCensus'
import * as censusesListTypeahead from './censusesListTypeahead'
import * as censusesListLookup from './censusesListLookup'

const slices = {
  censusesList,
  getCensus,
  createCensus,
  editCensus,
  deleteCensus,
  censusesListTypeahead,
  censusesListLookup
}

const censusTypes = _.mapValues(slices, (s) => s.slice.types)
const censusActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const censusSagas = _(slices).values().map('slice.sagas').flatten().value()
const censusSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { censusTypes, censusActions, censusSagas, censusSelectors }

export default combineReducers(reducers)
