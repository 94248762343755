import { setWithExpiry, getWithExpiry } from './localStorage'

const crossDomain = process.env.REACT_APP_ALLOW_SUBDOMAIN_COOKIE
const domain = crossDomain ? `;domain=${crossDomain}` : ''

export function getCookie(cname) {
  let name = cname + '='
  let ca = document.cookie.split(';')

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }

  //safari 3rd party cookies fallback
  if (getWithExpiry(cname)) return getWithExpiry(cname)

  return ''
}

export function setCookie(cname, cvalue, exp) {
  let d = new Date(exp * 1000)
  let expires = 'expires=' + d.toUTCString()

  // allow cookie sharing to subdomains (include nested) with domain propery of document cookie
  document.cookie = cname + '=' + cvalue + ';' + expires + ';secure;samesite=lax;path=/' + domain

  //safari 3rd party cookies fallback
  if (!getCookie(cname)) setWithExpiry(cname, cvalue, exp)
}

export function eraseCookie(cname) {
  document.cookie = cname + '=; Max-Age=-99999999;secure;samesite=lax;path=/' + domain

  //safari 3rd party cookies fallback
  if (localStorage.getItem(cname)) localStorage.removeItem(cname)
}
