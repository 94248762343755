import { combineReducers } from 'redux'
import _ from 'lodash'

import * as ratesList from './ratesList'
import * as getRate from './getRate'
import * as createRate from './createRate'
import * as editRate from './editRate'
import * as deleteRate from './deleteRate'
import * as ratesListLookup from './ratesListLookup'

const slices = {
  ratesList,
  getRate,
  createRate,
  editRate,
  deleteRate,
  ratesListLookup
}

const rateTypes = _.mapValues(slices, (s) => s.slice.types)
const rateActions = Object.assign({}, ..._(slices).values().map('slice.actions').value())
const rateSagas = _(slices).values().map('slice.sagas').flatten().value()
const rateSelectors = Object.assign({}, ..._(slices).values().map('slice.selectors').value())
const reducers = Object.assign({}, ..._(slices).values().map('default').value())

export { rateTypes, rateActions, rateSagas, rateSelectors }

export default combineReducers(reducers)
